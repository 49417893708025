import { createStore } from "vuex";
import { pageModule } from "@/store/pageModule.js";
import { userInfoModule } from "@/store/userInfoModule.js";
import { noticeAnswerModule } from "@/store/noticeAnswerModule.js";
import { modifyModule } from "@/store/modifyModule.js";
import { confirmedModule } from "@/store/confirmedModule.js";
import { confirmationStateModule } from "@/store/confirmationStateModule.js";
import { loadingModule } from "@/store/loadingModule.js";

const store = createStore({
  modules: {
    userInfo: userInfoModule,
    noticeAnswer: noticeAnswerModule,
    page: pageModule,
    modify: modifyModule,
    confirmed: confirmedModule,
    confirmationState: confirmationStateModule,
    loading: loadingModule
  }
});

export default store;
