import { createRouter, createWebHistory } from "vue-router";
import NoticeBase from "../views/NoticeBase.vue";
import S01StartProcedure from "../views/S01StartProcedure.vue";
import S02InquiryInfo from "../views/S02InquiryInfo.vue";
import S03ImportantSubjects from "../views/S03ImportantSubjects.vue";
import S04CustomerInfo from "../views/S04CustomerInfo.vue";
import S05_1Notice1 from "../views/S05_1Notice1.vue";
import S05_2Notice2 from "../views/S05_2Notice2.vue";
import S05_3Notice3 from "../views/S05_3Notice3.vue";
import S05_4Notice4 from "../views/S05_4Notice4.vue";
import S05_5Notice5 from "../views/S05_5Notice5.vue";
import S05_6Notice6 from "../views/S05_6Notice6.vue";
import S06Confirmation from "../views/S06Confirmation.vue";
import S07Complete from "../views/S07Complete.vue";
import S08Cancel from "../views/S08Cancel.vue";
import S91SystemError from "../views/S91SystemError.vue";
import store from "../store";

import api from "@/services/api";

const routes = [
  // エントリポイント
  {
    path: "/",
    name: "S01",
    component: S01StartProcedure,
    meta: {
      previousPage: null,
      nextPage: "S02"
    }
  },
  {
    path: "/index.html",
    name: "S01",
    component: S01StartProcedure,
    meta: {
      previousPage: null,
      nextPage: "S02"
    }
  },
  {
    path: "/inquiry",
    name: "S02",
    component: S02InquiryInfo,
    meta: {
      previousPage: "S01",
      nextPage: "S03"
    }
  },
  {
    path: "/notice",
    component: NoticeBase,
    children: [
      {
        path: "important",
        name: "S03",
        component: S03ImportantSubjects,
        meta: {
          previousPage: "S02",
          nextPage: "S04"
        }
      },
      {
        path: "/insured",
        name: "S04",
        component: S04CustomerInfo,
        meta: {
          previousPage: "S03",
          nextPage: "S05_1"
        }
      },
      {
        path: "/answer/1",
        name: "S05_1",
        component: S05_1Notice1,
        meta: {
          previousPage: "S04",
          nextPage: "S05_2"
        }
      },
      {
        path: "/answer/2",
        name: "S05_2",
        component: S05_2Notice2,
        meta: {
          previousPage: "S05_1",
          nextPage: "S05_3"
        }
      },
      {
        path: "/answer/3",
        name: "S05_3",
        component: S05_3Notice3,
        meta: {
          previousPage: "S05_2",
          nextPage: "S05_4"
        }
      },
      {
        path: "/answer/4",
        name: "S05_4",
        component: S05_4Notice4,
        meta: {
          previousPage: "S05_3",
          nextPage: "S05_5"
        }
      },
      {
        path: "/answer/5",
        name: "S05_5",
        component: S05_5Notice5,
        meta: {
          previousPage: "S05_4",
          nextPage: "S05_6"
        }
      },
      {
        path: "/answer/6",
        name: "S05_6",
        component: S05_6Notice6,
        meta: {
          previousPage: "S05_5",
          nextPage: "S06"
        }
      },
      {
        path: "/confirmation",
        name: "S06",
        component: S06Confirmation,
        meta: {
          previousPage: "S05_6",
          nextPage: "S06",
          requiresUserInfo: true,
          requiresNotice: true
        }
      }
    ]
  },
  {
    path: "/complete",
    name: "S07",
    component: S07Complete,
    meta: {
      previousPage: "S06",
      nextPage: null
    }
  },
  {
    path: "/Cancel",
    name: "S08",
    component: S08Cancel,
    meta: {
      previousPage: "S06",
      nextPage: null
    }
  },
  {
    path: "/SystemError",
    name: "S91",
    component: S91SystemError
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 画面遷移時にスクロールをトップに戻す
  scrollBehavior() {
    return { left: 0, top: 0, behavior: "instant" };
  }
});

router.beforeEach((to, from, next) => {
  // 遷移先画面
  const previousPage = to.meta.previousPage;
  let nextPage = to.meta.nextPage;

  // がん特約無しかつ現在のnextPageがS05_5の場合は、nextPageをS06に置き換える
  if (
    sessionStorage.getItem("necessaryNoticeCancer") === "0" &&
    nextPage === "S05_5"
  ) {
    nextPage = "S06";
  }

  // 遷移先 画面をストアに保存
  store.dispatch("page/setPages", {
    previousPage,
    nextPage
  });

  let requiresUserInfo = to.meta.requiresUserInfo;
  let requiresNotice = to.meta.requiresNotice;
  const userInfo = store.getters["userInfo/lastName"];
  const noticeAnswer = store.getters["noticeAnswer/noticeAnswer1"];

  // 正常系の画面遷移
  if (userInfo === undefined && noticeAnswer === undefined) {
    // 被保険者情報と告知情報がない場合
    if (requiresUserInfo && requiresNotice) {
      // 被保険者情報と告知情報が必要な画面であれば、apiを呼んでサーバから取得する

      let campaignId = from.query.cid;
      let manageId = from.query.mid;
      let deadline = from.query.d;
      let necessaryNoticeCancer = from.query.c;
      let hashParams = from.query.p;
      let date = deadline;

      if (
        !from.query.cid ||
        !from.query.mid ||
        !from.query.d ||
        !from.query.c ||
        !from.query.p ||
        !deadline
      ) {
        campaignId = sessionStorage.getItem("campaignId");
        manageId = sessionStorage.getItem("manageId");
        deadline = sessionStorage.getItem("deadline");
        necessaryNoticeCancer = sessionStorage.getItem("necessaryNoticeCancer");
        hashParams = sessionStorage.getItem("hashParams");
        date = sessionStorage.getItem("deadline");
      }

      const body = {
        manage_id: manageId,
        campaign_id: campaignId,
        telephone_marketing_id: campaignId.substr(0, 2),
        call_center_id: campaignId.substr(2, 2),
        security_id: campaignId.substr(5, 4),
        notice_expiration_date:
          date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2),
        necessary_notice_cancer: necessaryNoticeCancer,
        hash_param: hashParams
      };
      api
        .post("start_procedure/", body)
        .then(response => {
          const responseBody = response.data;
          const lastName = responseBody.data.last_name;
          const firstName = responseBody.data.first_name;
          const birthDate = responseBody.data.birth_date;
          const necessaryNoticeCancer =
            responseBody.data.notice.necessary_notice_cancer;
          const noticeAnswer1 =
            responseBody.data.notice.notice_answer_category_1;
          const noticeAnswer2 =
            responseBody.data.notice.notice_answer_category_2;
          const noticeAnswer3 =
            responseBody.data.notice.notice_answer_category_3;
          const noticeAnswer4 =
            responseBody.data.notice.notice_answer_category_4;
          const noticeAnswer5 =
            responseBody.data.notice.notice_answer_category_5;
          const noticeAnswer6 =
            responseBody.data.notice.notice_answer_category_6;

          store.dispatch("userInfo/setUserInfo", {
            userInfo: {
              lastName: lastName,
              firstName: firstName,
              birthYear: birthDate.substr(0, 4),
              birthMonth: birthDate.substr(5, 2),
              birthDate: birthDate.substr(8, 2)
            }
          });
          store.dispatch("noticeAnswer/setNoticeAnswer", {
            noticeAnswer: {
              noticeAnswer1: noticeAnswer1,
              noticeAnswer2: noticeAnswer2,
              noticeAnswer3: noticeAnswer3,
              noticeAnswer4: noticeAnswer4,
              noticeAnswer5: noticeAnswer5,
              noticeAnswer6: noticeAnswer6
            }
          });

          sessionStorage.setItem("campaignId", campaignId);
          sessionStorage.setItem("manageId", manageId);
          sessionStorage.setItem("deadline", deadline);
          sessionStorage.setItem(
            "necessaryNoticeCancer",
            necessaryNoticeCancer
          );
          sessionStorage.setItem("hashParams", hashParams);

          // 確認画面の各ボタンを活性化、非活性化する
          store.dispatch("confirmationState/setIsChecked", {
            isChecked: true
          });
          store.dispatch("confirmationState/setIsDisabledDownload", {
            isDisabledDownload: false
          });
          store.dispatch("confirmationState/setIsDisabledModify", {
            isDisabledModify: true
          });
          store.dispatch("confirmationState/setIsDisabledSend", {
            isDisabledSend: false
          });
          store.dispatch("confirmationState/setHideInterruption", {
            hideInterruption: true
          });

          next();

          store.dispatch("loading/setLoading", {
            loading: false
          });
        })
        .catch(() => {
          // エラー時の遷移はapi.jsに任せる
          return null;
        });
    } else {
      next();
    }
  } else {
    // 被保険者情報と告知情報が取得できていれば、通常の画面遷移を行う
    next();
  }
});

export default router;
