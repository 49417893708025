import Stepber from "@/components/Stepber.vue";
import NextSubmitBtn from "@/components/NextSubmitBtn.vue";
import BackBtn from "@/components/BackBtn.vue";
import BackConfirmationBtn from "@/components/BackConfirmationBtn.vue";
import moment from "moment";
import { Form as ValidationForm, Field as ValidationField } from "vee-validate";
export default {
  data() {
    return {
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      formValues: {
        lastName: this.$store.getters["userInfo/lastName"],
        firstName: this.$store.getters["userInfo/firstName"],
        birthYear: this.$store.getters["userInfo/birthYear"],
        birthMonth: this.$store.getters["userInfo/birthMonth"],
        birthDate: this.$store.getters["userInfo/birthDate"]
      },
      modalForeignerName: false
    };
  },
  computed: {
    yearObjects() {
      // プルダウン表示の年の設定
      const goBackYears = 54; // 2021年時 1949年生まれ＝満72歳
      const endYear = moment().year() - 18; // 2021年時2003年生まれ＝満18歳
      const startYear = endYear - goBackYears;
      const years = [...Array(goBackYears + 1).keys()].map(x => x + startYear);
      return years.map(x => {
        return {
          value: x,
          label: `${x} (${this.changeJapaneseYear(x)})`
        };
      });
    },
    // S06:入力内容確認画面からの遷移情報の取得
    getModifyS06() {
      return this.$store.getters["modify/modifyS06"];
    }
  },
  methods: {
    getDates(year, month) {
      if (!year || !month) {
        return [];
      }
      // 月末日を取得してプルダウンに表示する
      const finalDate = this.getFinalDate(year, month);
      return [...Array(finalDate).keys()].map(x => x + 1);
    },
    onChange() {
      // 年や月が変更されたとき、日が存在しなくなる場合があるので調整する
      // 例: 2018-12-31 を選択していて月が 12 から 2 に変更された場合、日を 28 にする
      const isValidBirthDate = moment({
        year: this.formValues.birthYear,
        month: this.formValues.birthMonth - 1,
        day: this.formValues.birthDate
      }).isValid();
      if (!isValidBirthDate) {
        this.formValues.birthDate = undefined;
      }
    },
    getFinalDate(year, month) {
      // 月末日の取得
      return moment({
        year,
        month: month - 1
      }).endOf("month").date();
    },
    changeJapaneseYear(year) {
      // 和暦の表示
      const opt = {
        year: "numeric"
      };
      return new Date(year, 0, 1).toLocaleDateString("ja-JP-u-ca-japanese", opt).match(/^.*年/);
    },
    getValidationState(dirty, validated, valid = null) {
      // 必須入力チェック
      return dirty || validated ? valid : null;
    },
    onSubmit(values) {
      // store に被保険者情報を保持する
      this.$store.dispatch("userInfo/setUserInfo", {
        userInfo: {
          lastName: values.lastName,
          firstName: values.firstName,
          birthYear: values.birthYear,
          birthMonth: values.birthMonth,
          birthDate: values.birthDate
        }
      });
      // バリデーションチェック＆次ページへ遷移
      this.$refs.nextSubmitBtn.next();
    },
    modalOpen() {
      this.modalForeignerName = true;
    },
    modalClose() {
      this.modalForeignerName = false;
    }
  },
  components: {
    Stepber,
    BackBtn,
    NextSubmitBtn,
    BackConfirmationBtn,
    ValidationForm,
    ValidationField
  }
};