// 各確認ボタンのユーザークリック情報
export const confirmedModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    confirmedImportant: false,
    confirmedSupport: false,
    confirmedDiseaseName: false,
    confirmedTable1: false,
    confirmedTable2: false
  },
  getters: {
    confirmedImportant: state => state.confirmedImportant,
    confirmedSupport: state => state.confirmedSupport,
    confirmedDiseaseName: state => state.confirmedDiseaseName,
    confirmedTable1: state => state.confirmedTable1,
    confirmedTable2: state => state.confirmedTable2
  },
  mutations: {
    setConfirmedImportant(state, payload) {
      state.confirmedImportant = payload.confirmedImportant;
    },
    setConfirmedSupport(state, payload) {
      state.confirmedSupport = payload.confirmedSupport;
    },
    setConfirmedDiseaseName(state, payload) {
      state.confirmedDiseaseName = payload.confirmedDiseaseName;
    },
    setConfirmedTable1(state, payload) {
      state.confirmedTable1 = payload.confirmedTable1;
    },
    setConfirmedTable2(state, payload) {
      state.confirmedTable2 = payload.confirmedTable2;
    },
    clear(state) {
      state.confirmedImportant = undefined;
      state.confirmedSupport = undefined;
      state.confirmedDiseaseName = undefined;
      state.confirmedTable1 = undefined;
      state.confirmedTable2 = undefined;
    }
  },
  actions: {
    setConfirmedImportant(context, payload) {
      context.commit("setConfirmedImportant", {
        confirmedImportant: payload.confirmedImportant
      });
    },
    setConfirmedSupport(context, payload) {
      context.commit("setConfirmedSupport", {
        confirmedSupport: payload.confirmedSupport
      });
    },
    setConfirmedDiseaseName(context, payload) {
      context.commit("setConfirmedDiseaseName", {
        confirmedDiseaseName: payload.confirmedDiseaseName
      });
    },
    setConfirmedTable1(context, payload) {
      context.commit("setConfirmedTable1", {
        confirmedTable1: payload.confirmedTable1
      });
    },
    setConfirmedTable2(context, payload) {
      context.commit("setConfirmedTable2", {
        confirmedTable2: payload.confirmedTable2
      });
    }
  }
};
