import Header from "@/components/Header.vue";
import Stepber from "@/components/Stepber.vue";
import Footer from "@/components/Footer.vue";
export default {
  methods: {
    close() {
      window.open("about:blank", "_self").close();
    }
  },
  components: {
    Header,
    Stepber,
    Footer
  }
};