import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import Stepber from "@/components/Stepber.vue";
import api from "@/services/api";
export default {
  data() {
    return {
      isChecked: this.$store.getters["confirmationState/isChecked"],
      isDisabledDownload: this.$store.getters["confirmationState/isDisabledDownload"],
      isDisabledModify: this.$store.getters["confirmationState/isDisabledModify"],
      isDisabledSend: this.$store.getters["confirmationState/isDisabledSend"],
      isLoading: false,
      fileURL: "",
      modalCancel: false,
      modalDownload: false,
      modalSend: false,
      modalOpenPdf: false
    };
  },
  methods: {
    // 被保険者・告知情報訂正時の画面遷移先指定、S06:入力内容確認画面から遷移した情報を store に保持
    modifyS04() {
      this.$router.push({
        name: "S04"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    modifyQ1() {
      this.$router.push({
        name: "S05_1"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    modifyQ2() {
      this.$router.push({
        name: "S05_2"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    modifyQ3() {
      this.$router.push({
        name: "S05_3"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    modifyQ4() {
      this.$router.push({
        name: "S05_4"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    modifyQ5() {
      this.$router.push({
        name: "S05_5"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    modifyQ6() {
      this.$router.push({
        name: "S05_6"
      });
      this.$store.dispatch("modify/setModifyS06", {
        modifyS06: "1"
      });
    },
    changeCheckbox() {
      // チェックボックスのチェック有無で告知控えダウンロードボタンの活性・非活性を変更
      if (this.isChecked) {
        this.isDisabledDownload = false;
      } else {
        this.isDisabledDownload = true;
      }
    },
    async noticeReceiptCreateDownload() {
      // 告知内容控えPDF作成・取得API
      const date = sessionStorage.getItem("deadline");
      const body = {
        birth_date: `${this.$store.getters["userInfo/birthYear"]}-${this.$store.getters["userInfo/birthMonth"]}-${this.$store.getters["userInfo/birthDate"]}`,
        last_name: this.$store.getters["userInfo/lastName"],
        first_name: this.$store.getters["userInfo/firstName"],
        notice_expiration_date: date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2),
        notice: {
          necessary_notice_cancer: sessionStorage.getItem("necessaryNoticeCancer"),
          notice_answer_category_1: this.$store.getters["noticeAnswer/noticeAnswer1"],
          notice_answer_category_2: this.$store.getters["noticeAnswer/noticeAnswer2"],
          notice_answer_category_3: this.$store.getters["noticeAnswer/noticeAnswer3"],
          notice_answer_category_4: this.$store.getters["noticeAnswer/noticeAnswer4"],
          notice_answer_category_5: this.$store.getters["noticeAnswer/noticeAnswer5"],
          notice_answer_category_6: this.$store.getters["noticeAnswer/noticeAnswer6"]
        }
      };
      const manage_id = sessionStorage.getItem("manageId");
      const campaign_id = sessionStorage.getItem("campaignId");
      return api.post(`${manage_id}/${campaign_id}/notice_receipt_create_download/`, body, {
        responseType: "blob"
      });
    },
    changeDownloadBtn() {
      // 各種ボタンを非活性、非表示にする
      this.isDisabledDownload = true;
      this.isDisabledModify = true;
      this.$store.dispatch("confirmationState/setHideInterruption", {
        hideInterruption: true
      });
      // Loading表示
      this.isLoading = true;
      // 告知内容控えPDF作成・取得API実行
      this.noticeReceiptCreateDownload().then(response => {
        const data = response.data;
        const file = new Blob([data], {
          type: "application/pdf"
        });
        this.fileURL = URL.createObjectURL(file);
        this.isLoading = false;
        this.modalOpenPdf = true;
        // ダウンロードボタンを活性表示に戻す
        this.isDisabledDownload = false;
      }).catch(() => {
        this.isLoading = false;
        // エラー時の遷移はapi.jsに任せる
        return null;
      });
    },
    openPdf() {
      // APIで事前にダウンロードした告知内容控えPDFを開く
      window.open(this.fileURL);
      // 「この内容で送信」ボタンを活性化する
      this.isDisabledSend = false;
    },
    async noticeComplete() {
      // 告知完了API
      const date = sessionStorage.getItem("deadline");
      const body = {
        notice_expiration_date: date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2)
      };
      const manage_id = sessionStorage.getItem("manageId");
      const campaign_id = sessionStorage.getItem("campaignId");
      return api.patch(`${manage_id}/${campaign_id}/notice_complete/`, body);
    },
    next() {
      this.isLoading = true;
      // 告知完了API実行
      this.noticeComplete().then(() => {
        this.$router.push({
          name: "S07"
        });
      }).catch(() => {
        // エラー時の遷移はapi.jsに任せる
        return null;
      });
      this.isLoading = false;
    },
    async entryCancel() {
      // 加入申し込み取り消しAPI
      const body = {
        birth_date: `${this.$store.getters["userInfo/birthYear"]}-${this.$store.getters["userInfo/birthMonth"]}-${this.$store.getters["userInfo/birthDate"]}`,
        last_name: this.$store.getters["userInfo/lastName"],
        first_name: this.$store.getters["userInfo/firstName"]
      };
      const manage_id = sessionStorage.getItem("manageId");
      const campaign_id = sessionStorage.getItem("campaignId");
      return api.patch(`${manage_id}/${campaign_id}/entry_cancel/`, body);
    },
    cancelNext() {
      // 加入申し込み取り消しAPI実行
      this.entryCancel().then(() => {
        this.$router.push({
          name: "S08"
        });
      }).catch(() => {
        // エラー時の遷移はapi.jsに任せる
        return null;
      });
    },
    noticeAnswerToText(noticeAnswer) {
      if (noticeAnswer === "1") {
        return "はい";
      } else {
        return "いいえ";
      }
    },
    modalCancelOpen() {
      this.modalCancel = true;
    },
    modalCancelClose() {
      this.modalCancel = false;
    },
    modalDownloadOpen() {
      this.modalDownload = true;
    },
    modalDownloadClose() {
      this.modalDownload = false;
    },
    modalSendOpen() {
      this.modalSend = true;
    },
    modalSendClose() {
      this.modalSend = false;
    },
    modalOpenPdfClose() {
      this.modalOpenPdf = false;
    }
  },
  computed: {
    // 被保険者・告知情報を store から取得して画面に表示する
    getLastName() {
      return this.$store.getters["userInfo/lastName"];
    },
    getFirstName() {
      return this.$store.getters["userInfo/firstName"];
    },
    getBirthYear() {
      return this.$store.getters["userInfo/birthYear"];
    },
    getBirthMonth() {
      return this.$store.getters["userInfo/birthMonth"];
    },
    getBirthDate() {
      return this.$store.getters["userInfo/birthDate"];
    },
    getNoticeAnswer1() {
      return this.noticeAnswerToText(this.$store.getters["noticeAnswer/noticeAnswer1"]);
    },
    getNoticeAnswer2() {
      return this.noticeAnswerToText(this.$store.getters["noticeAnswer/noticeAnswer2"]);
    },
    getNoticeAnswer3() {
      return this.noticeAnswerToText(this.$store.getters["noticeAnswer/noticeAnswer3"]);
    },
    getNoticeAnswer4() {
      return this.noticeAnswerToText(this.$store.getters["noticeAnswer/noticeAnswer4"]);
    },
    getNoticeAnswer5() {
      return this.noticeAnswerToText(this.$store.getters["noticeAnswer/noticeAnswer5"]);
    },
    getNoticeAnswer6() {
      return this.noticeAnswerToText(this.$store.getters["noticeAnswer/noticeAnswer6"]);
    },
    getNecessaryNoticeCancer() {
      return sessionStorage.getItem("necessaryNoticeCancer");
    }
  },
  components: {
    Stepber
  }
};