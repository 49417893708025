// 告知情報
export const noticeAnswerModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    noticeAnswer: {
      noticeAnswer1: undefined,
      noticeAnswer2: undefined,
      noticeAnswer3: undefined,
      noticeAnswer4: undefined,
      noticeAnswer5: undefined,
      noticeAnswer6: undefined
    }
  },
  getters: {
    noticeAnswer: state => state.noticeAnswer,
    noticeAnswer1: state => state.noticeAnswer.noticeAnswer1,
    noticeAnswer2: state => state.noticeAnswer.noticeAnswer2,
    noticeAnswer3: state => state.noticeAnswer.noticeAnswer3,
    noticeAnswer4: state => state.noticeAnswer.noticeAnswer4,
    noticeAnswer5: state => state.noticeAnswer.noticeAnswer5,
    noticeAnswer6: state => state.noticeAnswer.noticeAnswer6
  },
  mutations: {
    set(state, payload) {
      state.noticeAnswer = payload.noticeAnswer;
    },
    setNoticeAnswer1(state, payload) {
      state.noticeAnswer.noticeAnswer1 = payload.noticeAnswer1;
    },
    setNoticeAnswer2(state, payload) {
      state.noticeAnswer.noticeAnswer2 = payload.noticeAnswer2;
    },
    setNoticeAnswer3(state, payload) {
      state.noticeAnswer.noticeAnswer3 = payload.noticeAnswer3;
    },
    setNoticeAnswer4(state, payload) {
      state.noticeAnswer.noticeAnswer4 = payload.noticeAnswer4;
    },
    setNoticeAnswer5(state, payload) {
      state.noticeAnswer.noticeAnswer5 = payload.noticeAnswer5;
    },
    setNoticeAnswer6(state, payload) {
      state.noticeAnswer.noticeAnswer6 = payload.noticeAnswer6;
    },
    clear(state) {
      state.noticeAnswer.noticeAnswer1 = undefined;
      state.noticeAnswer.noticeAnswer2 = undefined;
      state.noticeAnswer.noticeAnswer3 = undefined;
      state.noticeAnswer.noticeAnswer4 = undefined;
      state.noticeAnswer.noticeAnswer5 = undefined;
      state.noticeAnswer.noticeAnswer6 = undefined;
    }
  },
  actions: {
    setNoticeAnswer(context, payload) {
      context.commit("set", {
        noticeAnswer: payload.noticeAnswer
      });
    },
    setNoticeAnswer1(context, payload) {
      context.commit("setNoticeAnswer1", {
        noticeAnswer1: payload.noticeAnswer1
      });
    },
    setNoticeAnswer2(context, payload) {
      context.commit("setNoticeAnswer2", {
        noticeAnswer2: payload.noticeAnswer2
      });
    },
    setNoticeAnswer3(context, payload) {
      context.commit("setNoticeAnswer3", {
        noticeAnswer3: payload.noticeAnswer3
      });
    },
    setNoticeAnswer4(context, payload) {
      context.commit("setNoticeAnswer4", {
        noticeAnswer4: payload.noticeAnswer4
      });
    },
    setNoticeAnswer5(context, payload) {
      context.commit("setNoticeAnswer5", {
        noticeAnswer5: payload.noticeAnswer5
      });
    },
    setNoticeAnswer6(context, payload) {
      context.commit("setNoticeAnswer6", {
        noticeAnswer6: payload.noticeAnswer6
      });
    }
  }
};
