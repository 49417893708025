import BackBtn from "@/components/BackBtn.vue";
import NextSubmitBtn from "@/components/NextSubmitBtn.vue";
import BackConfirmationBtn from "@/components/BackConfirmationBtn.vue";
import { Form as ValidationForm, Field as ValidationField } from "vee-validate";
export default {
  props: ["currentNotice", "isDisabled"],
  data() {
    return {
      formValues: {
        notice: null
      },
      isBorderNo: true,
      isPrimaryNo: false,
      isSecondaryNo: true,
      isBorderYes: true,
      isPrimaryYes: false,
      isSecondaryYes: true,
      isPushed: false,
      isRed: false
    };
  },
  methods: {
    getValidationState(dirty, validated, valid = null) {
      // 必須入力チェック
      if (dirty || validated) {
        this.isRed = true;
      }
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      // 「次へ」ボタン押下時にバリデーションチェックを実行
      const action = `noticeAnswer/setNoticeAnswer${this.currentNotice}`;
      const payload = {
        [`noticeAnswer${this.currentNotice}`]: this.formValues.notice
      };
      this.$store.dispatch(action, payload);
      this.$refs.nextSubmitBtn.next();
    },
    changeValue(values) {
      this.formValues.notice = values.notice;
      this.checkedColor();
    },
    checkedColor() {
      // ラジオボタンの枠線表示
      if (this.formValues.notice === "0") {
        this.isBorderNo = true;
        this.isPrimaryNo = true;
        this.isSecondaryNo = false;
        this.isBorderYes = false;
        this.isPushed = true;
        this.isRed = false;
      } else if (this.formValues.notice === "1") {
        this.isBorderYes = true;
        this.isPrimaryYes = true;
        this.isSecondaryYes = false;
        this.isBorderNo = false;
        this.isPushed = true;
        this.isRed = false;
      } else {
        this.isSecondaryNo = true;
        this.isSecondaryYes = true;
        this.isPushed = false;
        this.isRed = false;
      }
    },
    initCheckedColor() {
      // 質問に対する回答内容を取得
      this.formValues.notice = this.$store.getters[`noticeAnswer/noticeAnswer${this.currentNotice}`];
      this.checkedColor();
    }
  },
  mounted() {
    this.initCheckedColor();
  },
  computed: {
    // S06:入力内容確認画面からの遷移情報の取得
    getModifyS06() {
      return this.$store.getters["modify/modifyS06"];
    }
  },
  components: {
    BackBtn,
    NextSubmitBtn,
    BackConfirmationBtn,
    ValidationForm,
    ValidationField
  }
};