// S06:入力内容確認画面からの遷移情報
export const modifyModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    modify: {
      modifyS06: "0"
    }
  },
  getters: {
    modify: state => state.modify,
    modifyS06: state => state.modify.modifyS06
  },
  mutations: {
    set(state, payload) {
      state.modify = payload.modify;
    },
    setModifyS06(state, payload) {
      state.modify.modifyS06 = payload.modifyS06;
    },
    clear(state) {
      state.modify.modifyS06 = undefined;
    }
  },
  actions: {
    setModify(context, payload) {
      context.commit("set", {
        modify: payload.modify
      });
    },
    setModifyS06(context, payload) {
      context.commit("setModifyS06", {
        modifyS06: payload.modifyS06
      });
    }
  }
};
