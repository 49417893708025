import "core-js/modules/es.array.push.js";
export default {
  data: function () {
    return {
      modalInterruption: false
    };
  },
  methods: {
    interruption() {
      this.$router.push(sessionStorage.getItem("startProcedureUrl"));
      // 入力済みの被保険者・告知情報、操作に必要な情報を store から削除
      this.$store.commit("userInfo/clear");
      this.$store.commit("noticeAnswer/clear");
      this.$store.commit("modify/clear");
      this.$store.commit("confirmed/clear");
    },
    modalOpen() {
      this.modalInterruption = true;
    },
    modalClose() {
      this.modalInterruption = false;
    }
  }
};