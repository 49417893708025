import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "mt-4"
};
const _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_BContainer = _resolveComponent("BContainer");
  const _component_InterruptionBtn = _resolveComponent("InterruptionBtn");
  const _component_Footer = _resolveComponent("Footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header, {
    class: "header-position"
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_BContainer, null, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  })]), !$options.getHideInterruption ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_InterruptionBtn, {
    class: "btn-position"
  })])) : _createCommentVNode("", true), _createVNode(_component_Footer, {
    class: "footer-position"
  })]);
}