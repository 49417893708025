import "core-js/modules/es.array.push.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import api from "@/services/api";

// @ is an alias to /src
export default {
  data() {
    return {
      campaignId: "",
      manageId: "",
      necessaryNoticeCancer: "",
      deadline: "",
      hashParams: "",
      cardCompanyName: "",
      productName: "",
      startProcedureUrl: "",
      isLoading: this.$store.getters["loading/loading"]
    };
  },
  methods: {
    next() {
      this.$router.push({
        name: this.$store.getters["page/nextPage"]
      });
    },
    async requestStartProcedure() {
      // 手続開始API
      const date = this.deadline;
      const body = {
        manage_id: this.manageId,
        campaign_id: this.campaignId,
        telephone_marketing_id: this.campaignId.substr(0, 2),
        call_center_id: this.campaignId.substr(2, 2),
        security_id: this.campaignId.substr(5, 4),
        notice_expiration_date: date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2),
        necessary_notice_cancer: this.necessaryNoticeCancer,
        hash_param: this.hashParams
      };
      return api.post("start_procedure/", body);
    },
    // query parameter のバリデーションチェック
    validateQueryParams(queryParams) {
      try {
        const queryKeys = Object.keys(queryParams);
        const requiredKeys = ["cid", "mid", "c", "d", "p"];
        // parameter が一つもない場合
        if (!queryKeys.length) {
          throw new Error("NO QUERY PARAMETER ERROR");
        }
        // key の存在有無の確認
        requiredKeys.forEach(key => {
          if (!queryKeys.includes(key)) {
            throw new Error("QUERY PARAMETER KEY ERROR");
          }
        });
      } catch (error) {
        this.$router.push({
          name: "S91",
          query: {
            errorType: "1"
          }
        });
      }
    },
    preStartProcedure() {
      // query parameter の値取得
      this.campaignId = sessionStorage.getItem("campaignId");
      this.manageId = sessionStorage.getItem("manageId");
      this.necessaryNoticeCancer = sessionStorage.getItem("necessaryNoticeCancer");
      this.deadline = sessionStorage.getItem("deadline");
      this.hashParams = sessionStorage.getItem("hashParams");
      this.cardCompanyName = sessionStorage.getItem("cardCompanyName");
      this.productName = sessionStorage.getItem("productName");
      this.startProcedureUrl = sessionStorage.getItem("startProcedureUrl");
      const queryParams = this.$route.query;
      if (this.campaignId) {
        if (Object.keys(queryParams).length) {
          this.validateQueryParams(queryParams);
          this.campaignId = queryParams.cid;
          this.manageId = queryParams.mid;
          this.deadline = queryParams.d;
          this.necessaryNoticeCancer = queryParams.c;
          this.hashParams = queryParams.p;
          this.startProcedureUrl = this.$route.fullPath;
        }
      } else {
        this.validateQueryParams(queryParams);
        this.campaignId = queryParams.cid;
        this.manageId = queryParams.mid;
        this.deadline = queryParams.d;
        this.necessaryNoticeCancer = queryParams.c;
        this.hashParams = queryParams.p;
        this.startProcedureUrl = this.$route.fullPath;
      }

      // 手続開始API実行
      this.requestStartProcedure().then(response => {
        const responseBody = response.data;
        this.cardCompanyName = responseBody.data.group_contractor_info.card_company_name;
        this.productName = responseBody.data.group_contractor_info.product_name;
        const noticeProcedureStatus = responseBody.data.notice_procedure_status;
        if (noticeProcedureStatus === "02") {
          // ステータスが告知内容控えDL済みの場合、S06:確認画面に遷移する
          this.$router.push({
            name: "S06"
          });
        } else {
          // query parameter の値を session storage に保持する
          sessionStorage.setItem("campaignId", this.campaignId);
          sessionStorage.setItem("manageId", this.manageId);
          sessionStorage.setItem("deadline", this.deadline);
          sessionStorage.setItem("necessaryNoticeCancer", this.necessaryNoticeCancer);
          sessionStorage.setItem("hashParams", this.hashParams);
          // URL を session storage に保持する
          sessionStorage.setItem("startProcedureUrl", this.startProcedureUrl);
          // Loading画面を非表示にする
          this.isLoading = false;
        }
      }).catch(() => {
        // Loading画面を非表示にする
        this.isLoading = false;
        // エラー時の遷移はapi.jsに任せる
        return null;
      });
    }
  },
  mounted() {
    this.preStartProcedure();
  },
  components: {
    Header,
    Footer
  }
};