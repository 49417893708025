// Loading画面表示情報
export const loadingModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    loading: true
  },
  getters: {
    loading: state => state.loading
  },
  mutations: {
    set(state, payload) {
      state.loading = payload.loading;
    },
    clear(state) {
      state.loading = undefined;
    }
  },
  actions: {
    setLoading(context, payload) {
      context.commit("set", {
        loading: payload.loading
      });
    }
  }
};
