import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import NextBtn from "@/components/NextBtn.vue";
import BackBtn from "@/components/BackBtn.vue";
export default {
  components: {
    Header,
    Footer,
    NextBtn,
    BackBtn
  }
};