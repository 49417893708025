// 被保険者情報
export const userInfoModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    userInfo: {
      lastName: undefined,
      firstName: undefined,
      birthYear: undefined,
      birthMonth: undefined,
      birthDate: undefined
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    lastName: state => state.userInfo.lastName,
    firstName: state => state.userInfo.firstName,
    birthYear: state => state.userInfo.birthYear,
    birthMonth: state => state.userInfo.birthMonth,
    birthDate: state => state.userInfo.birthDate
  },
  mutations: {
    set(state, payload) {
      state.userInfo = payload.userInfo;
    },
    setLastName(state, payload) {
      state.userInfo.lastName = payload.lastName;
    },
    setFirstName(state, payload) {
      state.userInfo.firstName = payload.firstName;
    },
    setBirthYear(state, payload) {
      state.userInfo.birthYear = payload.birthYear;
    },
    setBirthMonth(state, payload) {
      state.userInfo.birthMonth = payload.birthMonth;
    },
    setBirthDate(state, payload) {
      state.userInfo.birthDate = payload.birthDate;
    },
    clear(state) {
      state.userInfo.lastName = undefined;
      state.userInfo.firstName = undefined;
      state.userInfo.birthYear = undefined;
      state.userInfo.birthMonth = undefined;
      state.userInfo.birthDate = undefined;
    }
  },
  actions: {
    setUserInfo(context, payload) {
      context.commit("set", {
        userInfo: payload.userInfo
      });
    },
    setLastName(context, payload) {
      context.commit("setLastName", {
        lastName: payload.lastName
      });
    },
    setFirstName(context, payload) {
      context.commit("setFirstName", {
        firstName: payload.firstName
      });
    },
    setBirthYear(context, payload) {
      context.commit("setBirthYear", {
        birthYear: payload.birthYear
      });
    },
    setBirthMonth(context, payload) {
      context.commit("setBirthMonth", {
        birthMonth: payload.birthMonth
      });
    },
    setBirthDate(context, payload) {
      context.commit("setBirthDate", {
        birthDate: payload.birthDate
      });
    }
  }
};
