import axios from "axios";
import router from "@/router";

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: process.env.VUE_APP_TIME_OUT,
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  }
});

// 共通エラー処理
api.interceptors.response.use(
  function (response) {
    // 正常な場合は何も処理しない
    return response;
  },
  async function (error) {
    const isTimeout = error.code === "ECONNABORTED";

    if (isTimeout) {
      router.push({
        name: "S91",
        query: { errorType: "9" }
      });

      let message = { response: { data: { message: "timeout" } } };
      return Promise.reject(message);
    } else if (!error.response) {
      router.push({
        name: "S91",
        query: { errorType: "9" }
      });
      return Promise.reject(error);
    } else {
      let status;

      if (!error.response || !error.response.status) {
        status = 500;
      } else {
        status = error.response.status;
      }

      console.log("intercepter", status);

      if (status === 403) {
        // メンテナンス時(WAFから403が返ってくる)
        location.replace("/public/index.html");
      } else {
        const parsedError = await onBlobHandler(error);

        router.push({
          name: "S91",
          query: { errorType: parsedError.errorType }
        });

        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

//BlobをJSONに変換します。
async function onBlobHandler(error) {
  if (error.request.responseType === "blob") {
    const text = await blobFileReader(error.response.data);
    return JSON.parse(text);
  } else {
    return error.response.data;
  }
}

const blobFileReader = blob => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject();
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(blob);
  });
};

export default api;
