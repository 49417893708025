export default {
  data() {
    return {
      isCurrent1: "",
      isCurrent2: "",
      isCurrent3: "",
      isCurrent4: "",
      isCurrent5: ""
    };
  },
  // 現在のステップを各ページから取得
  props: ["currentStep"],
  methods: {
    setIsCurrent() {
      if (this.currentStep === 1) {
        this.isCurrent1 = "is-current";
      } else if (this.currentStep === 2) {
        this.isCurrent2 = "is-current";
      } else if (this.currentStep === 3) {
        this.isCurrent3 = "is-current";
      } else if (this.currentStep === 4) {
        this.isCurrent4 = "is-current";
      } else if (this.currentStep === 5) {
        this.isCurrent5 = "is-current";
      }
    }
  },
  mounted() {
    this.setIsCurrent();
  }
};