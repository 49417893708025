import Stepber from "@/components/Stepber.vue";
import NoticeForm from "@/components/NoticeForm.vue";
export default {
  data: function () {
    return {
      modalKokuchi2: false,
      modal7Days: false,
      modalExamination: false,
      modalHospitalization: false,
      modalOperation: false
    };
  },
  components: {
    Stepber,
    NoticeForm
  },
  methods: {
    modalKokuchi2Open() {
      this.modalKokuchi2 = true;
    },
    modalKokuchi2Close() {
      this.modalKokuchi2 = false;
    },
    modal7DaysOpen() {
      this.modal7Days = true;
    },
    modal7DaysClose() {
      this.modal7Days = false;
    },
    modalExaminationOpen() {
      this.modalExamination = true;
    },
    modalExaminationClose() {
      this.modalExamination = false;
    },
    modalHospitalizationOpen() {
      this.modalHospitalization = true;
    },
    modalHospitalizationClose() {
      this.modalHospitalization = false;
    },
    modalOperationOpen() {
      this.modalOperation = true;
    },
    modalOperationClose() {
      this.modalOperation = false;
    }
  }
};