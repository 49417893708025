// S06:入力内容確認画面のボタン活性・非活性の情報保持
export const confirmationStateModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    isChecked: false,
    isDisabledDownload: true,
    isDisabledModify: false,
    isDisabledSend: true,
    hideInterruption: false
  },
  getters: {
    isChecked: state => state.isChecked,
    isDisabledDownload: state => state.isDisabledDownload,
    isDisabledModify: state => state.isDisabledModify,
    isDisabledSend: state => state.isDisabledSend,
    hideInterruption: state => state.hideInterruption
  },
  mutations: {
    setIsChecked(state, payload) {
      state.isChecked = payload.isChecked;
    },
    setIsDisabledDownload(state, payload) {
      state.isDisabledDownload = payload.isDisabledDownload;
    },
    setIsDisabledModify(state, payload) {
      state.isDisabledModify = payload.isDisabledModify;
    },
    setIsDisabledSend(state, payload) {
      state.isDisabledSend = payload.isDisabledSend;
    },
    setHideInterruption(state, payload) {
      state.hideInterruption = payload.hideInterruption;
    },
    clear(state) {
      state.isChecked = false;
      state.isDisabledDownload = true;
      state.isDisabledModify = false;
      state.isDisabledSend = true;
      state.hideInterruption = false;
    }
  },
  actions: {
    setIsChecked(context, payload) {
      context.commit("setIsChecked", {
        isChecked: payload.isChecked
      });
    },
    setIsDisabledDownload(context, payload) {
      context.commit("setIsDisabledDownload", {
        isDisabledDownload: payload.isDisabledDownload
      });
    },
    setIsDisabledModify(context, payload) {
      context.commit("setIsDisabledModify", {
        isDisabledModify: payload.isDisabledModify
      });
    },
    setIsDisabledSend(context, payload) {
      context.commit("setIsDisabledSend", {
        isDisabledSend: payload.isDisabledSend
      });
    },
    setHideInterruption(context, payload) {
      context.commit("setHideInterruption", {
        hideInterruption: payload.hideInterruption
      });
    }
  }
};
