import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BCol = _resolveComponent("BCol");
  const _component_IBiChevronRight = _resolveComponent("IBiChevronRight");
  const _component_BRow = _resolveComponent("BRow");
  const _component_BButton = _resolveComponent("BButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BButton, {
    class: "btn-size py-3",
    variant: "primary",
    onClick: _cache[0] || (_cache[0] = $event => $options.next()),
    disabled: $props.isDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_BRow, null, {
      default: _withCtx(() => [_createVNode(_component_BCol, {
        cols: "2"
      }), _createVNode(_component_BCol, {
        cols: "8"
      }, {
        default: _withCtx(() => [_createTextVNode("次へ")]),
        _: 1
      }), _createVNode(_component_BCol, {
        cols: "2"
      }, {
        default: _withCtx(() => [_createVNode(_component_IBiChevronRight)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["disabled"])]);
}