import "core-js/modules/es.array.push.js";
import Stepber from "@/components/Stepber.vue";
import BackBtn from "@/components/BackBtn.vue";
import NextBtn from "@/components/NextBtn.vue";

// 「注意喚起情報」パス
const pathNoticeManual = "/public/products/pdf/告知重要事項・個人情報の取扱い.pdf";
const pathNoticeSupport = "/public/products/pdf/【がん特約なし】20210406WEB告知告知サポート資料.pdf";
const pathNoticeSupportCancer = "/public/products/pdf/【がん特約あり】20210406WEB告知告知サポート資料.pdf";
const pathNoticeDiseaseName = "/public/products/pdf/202403病名についてのよくあるご質問（WEB告知用）.pdf";
export default {
  data: function () {
    return {
      isDisabled: true,
      isImportantBtnPushed: false,
      isSupportBtnPushed: false,
      isDiseaseNameBtnPushed: false,
      btnColor: "#035663"
    };
  },
  methods: {
    importantBtn() {
      // 「告知重要事項・個人情報の取扱い」ボタン押下時の処理
      try {
        window.open(pathNoticeManual);
      } catch (error) {
        // ダウンロード処理エラーの実装
        this.$router.push({
          name: "S91",
          query: {
            errorType: "9"
          }
        });
      }
      // ボタン押下の情報を保持
      this.isImportantBtnPushed = true;
      this.$store.dispatch("confirmed/setConfirmedImportant", {
        confirmedImportant: true
      });
      // ボタンがすべて押下されていたら「次へ」ボタンを活性化する
      if (this.isImportantBtnPushed && this.isSupportBtnPushed && this.isDiseaseNameBtnPushed) {
        this.isDisabled = false;
      }
    },
    supportBtn() {
      // 「告知サポート資料」ボタン押下時の処理
      try {
        if (sessionStorage.getItem("necessaryNoticeCancer") === "0") {
          window.open(pathNoticeSupport);
        } else {
          window.open(pathNoticeSupportCancer);
        }
      } catch (error) {
        // ダウンロード処理エラーの実装
        this.$router.push({
          name: "S91",
          query: {
            errorType: "9"
          }
        });
      }
      // ボタン押下の情報を保持
      this.isSupportBtnPushed = true;
      this.$store.dispatch("confirmed/setConfirmedSupport", {
        confirmedSupport: true
      });
      // ボタンがすべて押下されていたら「次へ」ボタンを活性化する
      if (this.isImportantBtnPushed && this.isSupportBtnPushed && this.isDiseaseNameBtnPushed) {
        this.isDisabled = false;
      }
    },
    diseaseNameBtn() {
      // 「病名についてのよくある質問」ボタン押下時の処理
      try {
        window.open(pathNoticeDiseaseName);
      } catch (error) {
        // ダウンロード処理エラーの実装
        this.$router.push({
          name: "S91",
          query: {
            errorType: "9"
          }
        });
      }
      // ボタン押下の情報を保持
      this.isDiseaseNameBtnPushed = true;
      this.$store.dispatch("confirmed/setConfirmedDiseaseName", {
        confirmedDiseaseName: true
      });
      // ボタンがすべて押下されていたら「次へ」ボタンを活性化する
      if (this.isImportantBtnPushed && this.isSupportBtnPushed && this.isDiseaseNameBtnPushed) {
        this.isDisabled = false;
      }
    },
    // ボタンが押下されたらボタンの背景、枠線の色を変更する
    changeBtnColor(event) {
      event.currentTarget.style.background = this.btnColor;
      event.currentTarget.style.border = this.btnColor;
    },
    initBtnColor() {
      // 「戻る」ボタンでこのページに戻ったときにボタン押下済みの情報を取得
      if (this.$store.getters["confirmed/confirmedImportant"] && this.$store.getters["confirmed/confirmedSupport"] && this.$store.getters["confirmed/confirmedDiseaseName"]) {
        // ボタン押下済みの状態にする
        this.isDisabled = false;
        document.getElementById("important").style.background = "#035663";
        document.getElementById("support").style.background = "#035663";
        document.getElementById("disease-name").style.background = "#035663";
        document.getElementById("important").style.border = "#035663";
        document.getElementById("support").style.border = "#035663";
        document.getElementById("disease-name").style.border = "#035663";
      }
    }
  },
  mounted() {
    this.initBtnColor();
  },
  components: {
    Stepber,
    BackBtn,
    NextBtn
  }
};