export default {
  data: function () {
    return {
      modal1: false
    };
  },
  methods: {
    modalOpen() {
      this.modal1 = true;
    },
    modalClose() {
      this.modal1 = false;
    }
  }
};