import Header from "@/components/Header.vue";
import InterruptionBtn from "@/components/InterruptionBtn.vue";
import Footer from "@/components/Footer.vue";
export default {
  computed: {
    getHideInterruption() {
      return this.$store.getters["confirmationState/hideInterruption"];
    }
  },
  components: {
    Header,
    InterruptionBtn,
    Footer
  }
};