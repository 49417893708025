// ページ情報
export const pageModule = {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state: {
    previousPage: undefined,
    nextPage: undefined,
    backable: true
  },
  getters: {
    previousPage: state => state.previousPage,
    nextPage: state => state.nextPage,
    backable: state => state.backable
  },
  mutations: {
    setPreviousPage(state, payload) {
      state.previousPage = payload.previousPage;
    },
    setNextPage(state, payload) {
      state.nextPage = payload.nextPage;
    },
    setBackable(state, payload) {
      state.backable = payload.backable;
    },
    clear(state) {
      state.previousPage = undefined;
      state.nextPage = undefined;
    }
  },
  actions: {
    setPages(context, payload) {
      context.commit("clear");
      context.commit("setPreviousPage", {
        previousPage: payload.previousPage
      });
      context.commit("setNextPage", {
        nextPage: payload.nextPage
      });
    },
    setBackable(context, payload) {
      context.commit("setBackable", {
        backable: payload.backable
      });
    }
  }
};
