import "core-js/modules/es.array.push.js";
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    next() {
      this.$router.push({
        name: this.$store.getters["page/nextPage"]
      });
    }
  }
};