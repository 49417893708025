import Stepber from "@/components/Stepber.vue";
import NoticeForm from "@/components/NoticeForm.vue";
export default {
  data: function () {
    return {
      isChecked: false,
      modalExamination: false
    };
  },
  methods: {
    changeCheckbox() {
      // 表2に対するチェックボックスのチェックの有無を保持
      if (this.isChecked) {
        this.$store.dispatch("confirmed/setConfirmedTable2", {
          confirmedTable2: true
        });
      }
    },
    initIsChecked() {
      // 「戻る」ボタンでこのページに戻ったときにチェックが付いた状態にする
      if (this.$store.getters["confirmed/confirmedTable2"]) {
        this.isChecked = true;
      }
    },
    modalOpen() {
      this.modalExamination = true;
    },
    modalClose() {
      this.modalExamination = false;
    }
  },
  mounted() {
    this.initIsChecked();
  },
  components: {
    Stepber,
    NoticeForm
  }
};