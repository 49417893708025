import FooterSorry from "@/components/FooterSorry.vue";
import { ERROR_MESSAGES } from "@/consts";
export default {
  data() {
    return {
      errorType: this.$route.query.errorType
    };
  },
  computed: {
    detail() {
      if (this.errorType === "") {
        return ERROR_MESSAGES["9"];
      }
      return ERROR_MESSAGES[this.errorType];
    }
  },
  methods: {
    close() {
      window.open("about:blank", "_self").close();
    }
  },
  components: {
    FooterSorry
  }
};